import React from "react";
//import "react-responsive-carousel/lib/styles/carousel.css";
import { Carousel } from 'react-responsive-carousel';

class HomepageGallery extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                <Carousel
                    showThumbs={false}
                    showStatus={false}
                    autoPlay interval={7000} infiniteLoop
                >
                    <div className="homepage-gallery slide">
                        <img src="/images/Gallery/wall1.png" className="focused" />
                    </div>
                    <div className="homepage-gallery slide">
                        <img src="/images/Gallery/wall2.png" />
                    </div>
                    <div className="homepage-gallery slide">
                        <img src="/images/Gallery/wall3.png" />
                    </div>
                    <div className="homepage-gallery slide">
                        <img src="/images/Gallery/supressors.png" />
                    </div>
                    <div className="homepage-gallery slide">
                        <img src="/images/Gallery/floor.png" />
                    </div>
                </Carousel>
            </div>
        );
    }
}

export default HomepageGallery;